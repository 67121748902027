/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="3"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="General Donation SMSF, INDIA offline" />';
const PageContent = React.memo(PageContentNonMemoized);

const SmsfKanhaBuildingOffline = () => {
  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers(
        "smsf-india-general-donation-kanha-building-offline",
        event
      ),
    []
  );

  return (
    <LayoutHome
      seoTitle="Donation for SMSF India - General Fund - Kanha Building Offline – Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations Registration</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>
          Donation for SMSF India - General Fund - Kanha Building Offline
        </h3>
        <p>
          <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
          Spirituality Foundation, India. Sahaj Marg Spirituality Foundation
          supports all the events and programs undertaken by{" "}
          <b>heartfulness.</b> On completion of the donation registration, a
          confirmation email with instructions will be sent to you.
        </p>
        <p>
          Donation to this fund would be used specifically for the construction
          works at Kanha, Hyderabad.
        </p>
        <br />
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default SmsfKanhaBuildingOffline;
